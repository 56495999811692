*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: aqua;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex {
  display: flex;
  height: 75vh;
  /* gap: 2rem; */
  font-size: 3rem;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  grid-template-rows: repeat(7, 1fr);
  gap: 1rem;
  padding: 2rem;
  background-color: whitesmoke;
  border: 2px solid sandybrown;
}

.board-letters {
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.board-letters:hover {
  background-color: blueviolet;
  border-radius: 25%;
  color: whitesmoke;
}

.words-to-find-container {
  display: flex;
  flex-direction: column;
  margin: 0 5rem;
}

.words-to-find {
  text-transform: uppercase;
  margin: 0.5rem 0;
  text-align: center;
}

.clicked-words-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.clicked-words {
  text-transform: uppercase;
}

.btn-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.btn {
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  font-size: 1.2rem;
  color: whitesmoke;
  cursor: pointer;
}

.btn-clear-selection {
  background-color: black;
}

.btn-submit {
  background-color: cadetblue;
}
